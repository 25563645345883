.container {

}

.level-info {
  display: flex;
  align-items: center;
}

.dial {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  --size: 89px;

  position: absolute;
  top: 16px;
  height: var(--size);
  width: var(--size);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px dashed #2F3241;
  border-radius: 999px;

  > span:first-child {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: white;
  }

  > hr {
    height: 1px;
    width: 52px;

    background: #2F3241;
    border: none;
  }

  > span:last-child {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #747992;
  }
}

.milestone {
  margin-left: 28px;

  width: 0;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span:nth-child(1) {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #575C74;
  }

  > span:nth-child(2) {
    margin-top: 8px;

    font-weight: 600;
    font-size: 17px;
    white-space: nowrap;
    line-height: 21px;
    color: #23B843;
  }

  > span:nth-child(3) {
    margin-top: 6px;

    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #575C74;

    > span {
      color: #ABB4CC;
    }
  }
}
.container.mobile .milestone {
  margin-left: 17px;
}

.stats {
  margin-top: 35px;

  display: flex;
  flex-direction: column;
}

.stat {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  padding: 14px 22px 15px;

  background: #1B202E;
  border: 2px solid #2B2F45;
  border-radius: 14px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  &:not(:first-child) {
    margin-top: 9px;
  }

  > span:first-child {
    color: #E0E3EA;
  }

  > span:last-child {
    color: #575C74;
  }
}
.container.mobile .stat:not(:first-child) {
  margin-top: 6px;
}
