.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  position: absolute !important;
  top: 0; left: 0;
  width: 100%;
  height: 56.75%;
  box-sizing: border-box;
}

.scroll-viewport {
  flex: 1;
  position: relative;
  overflow-y: scroll;
}

.scroll-content {
  padding:
    calc(0.52 * (100vh - var(--navbar-height)))
    10px
    30px;
}

.sidebar {

}
