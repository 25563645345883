.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rocket {
  position: absolute;
}

.line-container {
  height: 100%;
  width: 100%;

  /* Hacky way to access the line of the chart */
  svg > g > path {
    stroke: url(#chart-line-gradient);
    stroke-linecap: round;
  }
}

.display {
  position: absolute;
  left: 21%;
  top: 10.4%;

  display: flex;
  flex-direction: column;

  /* Round over */
  > span:first-child {
    margin-left: 10px;
  
    font-weight: 800;
    font-size: 26px;
    line-height: 31px;
    text-transform: capitalize;
    color: #E3E6F1;
  }

  /* Multiplier */
  > span:last-child {
    margin-top: 7px;
  
    font-weight: 500;
    font-size: 85px;
    line-height: 103px;
    color: #E3E6F1;
  }
}
.container.mobile > .display {
  top: unset;
  left: unset;

  align-items: center;

  /* Round over */
  > span:first-child {
    display: none;
    margin: 0;

    font-size: 16px;
    line-height: 19px;
  }

  /* Multiplier */
  > span:last-child {
    margin: 0;

    font-size: 44px;
    line-height: 53px;
  }
}
.container.round-over > .display {
  /* Round over */
  > span:first-child {
    display: inline;
  }
}
