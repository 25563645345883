.container {
  --item-padding: 5px;

  display: flex;
  align-items: center;

  padding: 11px calc(15px - var(--item-padding));

  background: linear-gradient(90.21deg, #1E2A47 0.09%, #1D2538 29.94%, #1B2335 98.05%);
  border: 1px solid #313C59;
  border-radius: 11px;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.1);

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: white;

  > * {
    width: 23%;
    padding: 0 var(--item-padding);
    box-sizing: border-box;
  }

  &.mobile > * {
    width: 28.33%;
  }
}

.profile {
  display: flex;
  align-items: center;
  width: 31%;
}
.container.mobile > .profile {
  width: 15%;
}

.image {
  --size: 29px;

  flex-shrink: 0;

  height: var(--size);
  width: var(--size);

  border-radius: var(--size);
}

.name {
  margin-left: 11px;
}

.amount,
.bank {
  color: #FFAC2F;
}

.amount {
  
}

.multiplier {
  padding: 0;

  > span {
    padding: 5px 9px;

    background: #7B12E5;
    border-radius: 7px;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}
.container.mobile > .multiplier > span {
  font-size: 11px;
  line-height: 13px;
}

.bank {
  text-align: right;
}
