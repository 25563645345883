.container {
  display: flex;
  align-items: center;

  padding: 16px;

  line-height: 0;

  > input {
    display: none;
  }
}
