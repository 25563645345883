.container {
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  display: flex;

  border-radius: 8px;
  overflow: hidden;

  > label {
    position: absolute;
    top: 18px;
    left: 20px;

    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    text-transform: capitalize;
    line-height: 15px;
    color: #6B6C83;

    pointer-events: none;
  }

  > input {
    padding: 40px 20px 18px;
    width: 100%;

    background: #1D212F;
    border-radius: inherit;
    border: none;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: white;
  }
}
.container.mobile > .input-container {
  > label {
    top: 12px;
  }

  > input {
    padding: 31px 20px 12px;
  }
}

.amount-buttons {
  display: flex;

  margin-top: 8px;
}

.amount-button {
  padding: 7px 18px;

  background: #1D212F;
  border-radius: 8px;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #797994;

  transition: color var(--tr-dur) var(--tr-ease);
}
.amount-button:not(:first-child) {
  margin-left: 5px;
}
.amount-button[data-state=checked] {
  color: white;
}

.submit-button {
  margin-top: 41px;
}
.container.mobile > .submit-button {
  margin-top: 11px;
}
