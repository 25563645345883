.container {
  display: flex;
  justify-content: space-between;

  height: var(--navbar-height);
  padding-left: 35px;
  padding-right: 67px;

  background: #292C34;
}

.list {
  display: flex;
  height: 100%;

  margin: 0;
  padding: 0;
}

.left {
  display: flex;
  align-items: center;
}

.left > div {
  height: 100%;
}

.branding {
  display: flex;
  align-items: center;

  text-decoration: none;

  > h1 {
    margin-left: 11px;

    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
    color: white;
  }
}

.list {
  --item-padding: 15.5px;

  display: flex;
  margin: 0 calc(68px - var(--item-padding));
}

.list-item {
  display: flex;
  align-items: center;

  padding: 0 var(--item-padding);

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: var(--col-grey);

  transition: color var(--tr-dur) var(--tr-ease);
}
.list-item.active {
  color: #FFFDF8;
}

.right {
  display: flex;
  align-items: center;
}

.notification-button {
  
}

.balance {
  margin-left: 17px;
}

.user-dropdown {
  margin-left: 20px;
}
