.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  position: absolute !important;
  top: 29px;
  left: 8px;
  right: 8px;
}

.scroll-viewport {
  flex: 1;
  position: relative;
  overflow-y: scroll;
}

.scroll-content {
  padding: 283px 10px 30px;
}

.sidebar {

}
