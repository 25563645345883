.container {
  --gradient-border-width: 2px;
  --gradient-border-bg: linear-gradient(180deg, #212330 0%, #181927 7%);
  --gradient-border-box-bg: #181927;
  --sidebar-padding: 23px;

  position: relative;
  min-width: 355px;

  border-radius: 32px;
}

.content {
  display: flex;
  flex-direction: column;

  padding: var(--sidebar-padding);
  /* pass to tab buttons for better clickability */
  padding-top: 0;
}

.betting {
  display: flex;
  flex-direction: column;
}

.tab-list {
  margin: 0 20px;
}

.tab-content {
  display: flex;
  flex-direction: column;

  outline: none;

  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  label {
    margin-bottom: 16px;

    text-transform: capitalize;
    color: #E3E6F1;
  }

  input {
    padding: 17px 20px 16px;
  }
}
.container.mobile .tab-content {
  input {
    padding: 19px 30px;
  }
}

.bet-amount,
.auto-cash-out {
  margin-top: 38px;
}

.chest-button {
  margin-top: 23px;

  text-transform: uppercase;
}

.bet-button {
  --padding: 17px !important;

  margin-top: 14px;

  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: uppercase;
}

.players {
  flex: 1;
  margin-top: 43px;
}

.container.mobile .players {
  margin-top: 35px;
}
