.container {
  display: flex;

  border-radius: 8px;

  > input {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    &::placeholder {
      font-weight: 500;
      color: #777A82;
    }
  }

  > input[type=text] {
    flex: 1;
    
    width: 0;
    padding: 18px 20px;

    background: #181C28;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    border: none;

    color: white;
  }
}
.container:not(.inline) {
  flex-direction: column;

  > input[type=text] {
    width: 100%;
    box-sizing: border-box;

    border-radius: inherit;
  }
}

.inline-button {
  padding: 18px 39px;
  
  background: #1D212F;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border: none;

  color: #919FFF;

  transition: color var(--tr-dur) var(--tr-ease);

  &:hover {
    color: white;
  }
}

.cta-button {
  margin-top: 11px;
}
