.container {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 29px;

  background: #1F222B;
  border: 2px solid #333741;
  border-radius: 55px;
}

.value {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  color: white;
}

.button {
  position: absolute;
  width: 19px;
  height: 22px;
  right: -5px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(0deg, #3D55EB 0%, #7386FF 100%);
  border-radius: 12px;

  > svg {
    position: relative;
  }
}

.button::before {
  --offset: 1px;

  content: '';
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));

  background: linear-gradient(180deg, #586DF8 0%, #3D55EB 100%);
  border-radius: 11px;
}
