.container {
  --gradient-border-width: 2px;
  --gradient-border-bg: linear-gradient(180deg, #212330 0%, #181927 7%);
  --gradient-border-box-bg: #181927;

  position: relative;
  min-width: 285px;

  border-radius: 32px;
}

.inner {
  --h-padding: 21px;

  padding: 23px var(--h-padding) 27px;

  > h4 {
    margin-top: 46px;
    margin-bottom: 26px;

    font-weight: 600;
    font-size: 15px;
    text-transform: capitalize;
    line-height: 18px;
    color: #575C74;
  }
}
.container.mobile .inner {
  > hr {
    height: 1px;
    margin: 23px calc(-1 * var(--h-padding));

    background: #2F3241;
    border: none;
  }

  > h4 {
    margin-top: 0;
    margin-bottom: 17px;
  }
}

.header {
  display: flex;
  align-items: center;

  padding: 20px;

  background: #161723;
  border: 1px solid #313C59;
  border-radius: 21px;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.1);
}
.container.mobile .header {
  padding: 16px 21px;
}

.user-image {
  --size: 61px;

  flex-shrink: 0;

  height: var(--size);
  width: var(--size);

  border-radius: 999px;
}
.container.mobile .user-image {
  --size: 41px;
}

.user-info {
  width: 0;
  flex: 1;

  margin-left: 17px;

  display: flex;
  flex-direction: column;

  > span:first-child {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #E0E3EA;
  }

  > span:last-child {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #6B95FF;
  }
}
.container.mobile .user-info {
  > span:first-child {
    font-size: 15px;
    line-height: 18px;
  }
}

.promo-form {
  margin-top: 25px;

  display: flex;
  flex-direction: column;

  > input[type=text] {
    padding: 19px 22px;
  }

  > button {
    margin-top: 16px;
    text-transform: uppercase;
  }
}
.container.mobile .promo-form {
  margin-top: 17px;

  flex-direction: row;

  > input[type=text] {
    flex: 1;
    width: 0;
  }

  > button {
    margin-top: 0;
    margin-left: 10px;
  }
}

.stats {

}

.list {

}
