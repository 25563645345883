.container {
  --layout-h-padding: 23px;
  --layout-v-padding: 40px;
  --navbar-height: 92px;

  flex: 1;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  background: linear-gradient(180deg, #181A23 0%, #0D101A 100%);
}
.container.mobile {
  --navbar-height: 82px;

  background: #1D1E23;
}

.navbar {

}

.mobile-navbar {
  
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  max-height: calc(100vh - var(--navbar-height));
}

.sidebar {
  flex-shrink: 0;
}

.mobile-sidebar {
  z-index: 3;
}

.page {
  position: relative;
  flex: 1;
  display: flex;
  padding: var(--layout-v-padding) 0;
  padding-left: var(--layout-h-padding); 
}
/* Leaderboard has full page scrolling so cannot 
be constrained by vertical padding */
.container.leaderboard .page {
  padding-top: 0;
  padding-bottom: 0;
}
.container.mobile .page {
  padding: 0;
}

.backdrop {
  object-fit: cover;
  opacity: 0.06;
}

.main {
  flex: 1;
  position: relative;
  display: flex;
  margin-right: var(--layout-h-padding);
}
.container.mobile .main {
  flex-direction: column;
  margin: 0;
}

.chatbar {
  position: relative;
  width: 268px;
}
.container.leaderboard .chatbar {
  margin: var(--layout-v-padding) 0;
}

.content-blocker {
  z-index: 2;
}
