.container {
  width: 482px;
}

.content {
  display: flex;
  flex-direction: column;

  padding: 29px 36px 33px;

  > p {
    margin: 0;

    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #90949E;
  }

  > label {
    margin-top: 36px;
    
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #D9B432;
  }

  > input[type=text] {
    margin-top: 11px;
    min-width: 0;
    padding: 18px 20px;

    background: #1E222E;
    border-radius: 12px;
    border: none;

    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: white;
  }
}
.container.mobile > .content {
  padding: 22px 20px 33px;

  > label {
    margin-top: 11px;
  }
}

.confirm-button {
  margin-top: 23px;

  text-transform: uppercase;
}
