.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 21px 9px;

  background: linear-gradient(90.21deg, #1E2A47 0%, #1D2538 30%, #1B2335 100%);
  border: 1px solid #313C59;
  border-radius: 14px;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.1);

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  &.mobile {
    padding: 12px 17px 11px;

    border-radius: 11px;
  }
}

.profile {
  flex: 1;
  width: 0;

  display: flex;
  align-items: center;

  /* Name */
  > span {
    color: white;
  }
}

.image {
  --size: 29px;

  flex-shrink: 0;

  margin-right: 11px;

  height: var(--size);
  width: var(--size);

  border-radius: 999px;
}

.spacer {
  min-width: 1ch;
}

.amount {
  color: #FFAC2F;
}
