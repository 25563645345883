.container {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity var(--tr-dur) var(--tr-ease);
}
.container.visible {
  opacity: 1;
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;

  transform: scale(calc(2.85 * var(--scale)));
}

.lottie {
  --offset-x: -40px;

  position: absolute;
  height: 200px;
  width: 200px;
}

.lottie.flying {
  height: 41px;
  width: 150px;
  margin-right: calc(15px - var(--offset-x));
}

.lottie.breaking {
  margin-top: 10px !important;
  margin-left: calc(85px + var(--offset-x));
  transform: scale(0.9);
}

.lottie.explosion {
  margin-left: calc(59px + var(--offset-x));
}
