.container {
  position: relative;
  width: 127px;

  background: #1A1F2B;
}

.scroll-area {
  height: 100%;
}

.links {
  --padding: 21.5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--padding);
  margin: 0;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--padding);

  text-decoration: none;

  > svg {
    fill: var(--col-grey);
  }

  > span {
    margin-top: 9px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--col-grey);
  }

  > * {
    transition: all var(--tr-dur) var(--tr-ease);
  }
}
.link.active {
  > svg {
    fill: var(--col-ow-2);
  }

  > span {
    color: var(--col-ow-2);
  }
}
