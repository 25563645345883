.container {
  position: relative;
  margin: 16px;

  border-radius: 19px;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
.container.mobile > .close-button {
  right: 8px;
}
