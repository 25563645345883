.container {
  position: fixed;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  opacity: 0;
  pointer-events: none;

  transition: opacity var(--tr-dur) var(--tr-ease);
}
.container.visible {
  opacity: 1;
  pointer-events: all;
}
