.container {
  width: 861px;
}

.scroll-area {
  flex: 1;
}

.content {
  padding: 29px 36px 39px;

  display: flex;
  flex-direction: column;

  > p {
    margin-bottom: 0;

    font-weight: 500;
    font-size: 17px;
    line-height: 21px;    
    color: #90949E;

    &:first-child {
      margin: 0;
    }
  }
}
.container.mobile .content {
  padding: 22px 20px 44px;

  > p {
    font-size: 13px;
    line-height: 16px;
  }
}
