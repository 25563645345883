.container {
  display: flex;
  align-items: center;

  height: var(--navbar-height);

  border-bottom: 1px solid #343945;
}

.menu-toggle {
  align-self: stretch;
}

.logo {
  margin-left: 4px;
}

.spacer {
  flex: 1;
}

.notification-button {
  align-self: stretch;

  /* Use an inner div to prevent setting a button as `display: flex` */
  > div {
    height: 100%;
    box-sizing: border-box;
    padding: 20px;

    display: flex;
    align-items: center;

    line-height: 0;
  }
}

.user-dropdown {
  padding-right: 18px;
}
