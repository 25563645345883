.container {
  display: flex;
  flex-direction: column;

  padding: 44px 20px;

  background: #151823;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.container.mobile {
  padding: 12px 0;
}

.item {
  position: relative;
  padding: 14px 21px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 12px;

  &:not(:first-child) {
    margin-top: 9px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

    background: linear-gradient(180deg, #4E5EEA 0%, #7684FF 100%);
    border-radius: inherit;

    opacity: 0;
  }
  &[data-state=checked]::before {
    opacity: 1;
    transition: opacity var(--tr-dur) var(--tr-ease);
  }

  > span {
    position: relative;
    margin-right: 7px;

    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #B5B8C9;

    transition: all var(--tr-dur) var(--tr-ease);
  }
  &[data-state=checked] > span {
    color: white;
  }
}
.container.mobile > .item {
  padding: 12px 20px;
  margin-top: unset;
  min-width: 117px;

  &[data-state=checked] > span {
    font-weight: 600;
  }

  &::before {
    content: none;
  }
}

.image {
  --size: 20px;

  height: var(--size);
  width: var(--size);
}
