.container {
  --size: 40px;

  height: var(--size);
  width: var(--size);

  background: #1F222B;
  border: 2px solid #333741;
  border-radius: 999px;

  line-height: 0;
}
