:root {
  --tr-dur: 200ms;
  --tr-ease: ease-out;

  --col-ow-1: #E3E6F1;
  --col-ow-2: #E0E3EA;
  --col-grey: #777A82;

  --disabled-opacity: 0.22;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Inter', sans-serif;
  color: white;
}

#react {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

*:disabled {
  cursor: not-allowed;
}

h3 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: white;
}

input[type=text],
input[type=number] {
  outline: none;
}

button,
input[type=submit] {
  cursor: pointer;
}

button {
  padding: 0;
  background: none;
  border: none;
}

.ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-ml {
  --ellipsis-max-lines: 2;

  display: -webkit-box;
  -webkit-line-clamp: var(--ellipsis-max-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.contained {
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 100%;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number].no-spin {
  -moz-appearance:textfield; /* Firefox */
}

.gradient-border-box {
  --gradient-border-width: 2px;
  --gradient-border-bg: linear-gradient(to right, red, orange);
  --gradient-border-box-bg: green;

  position: relative;
  z-index: 1;

  border: solid var(--gradient-border-width) transparent;
}
.gradient-border-box::before,
.gradient-border-box::after {
  content: "";
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: calc(-1 * var(--gradient-border-width));
  border-radius: inherit;
  z-index: -1;
}
/* Border */
.gradient-border-box::before {
  background: var(--gradient-border-bg);
}
/* Background */
.gradient-border-box::after {
  box-sizing: border-box;
  background: var(--gradient-border-box-bg);
  background-clip: padding-box;
  border: solid var(--gradient-border-width) transparent;
}
