.container {
  position: relative;
  display: flex;
  align-items: center;

  > svg {
    --border-width: 2px;
    
    border-top: var(--border-width) solid #8896F3;
    border-radius: 11px;

    /* Fix graphical issue (tiny gab below border) when zoomed out */
    background: linear-gradient(180deg, #5970FC var(--border-width), rgba(78, 101, 252, 0) 15%);
  }

  /* Text */
  > span {
    position: absolute;
    left: 26px;

    /* Fix vertical position due to top border on SVG */
    margin-bottom: 2px;

    font-style: italic;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    line-height: 27px;
    color: white;
  }
}
