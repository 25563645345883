.container {
  display: flex;
  flex-direction: column;

  padding-top: 31px;

  background: linear-gradient(180deg, #2C313C 0%, #202531 100%);
  border-radius: 32px 0px 0px 32px;
  
  > h3 {
    margin-left: 41px;
    margin-bottom: 10px;
  }
}

.separator-top {
  height: 1px;
  margin: 0 23px;

  background: #3B4253;
}

.scroll-area {
  flex: 1;
}

.messages {
  padding: 13.5px;
  padding-top: 20px;
}

.message:not(:first-child) {
  margin-top: 7px;
}

.separator-bottom {
  height: 2px;
  background: #3B4253;
}

.form {
  display: flex;
  height: 78px;

  > input {
    flex: 1;

    height: 100%;
    padding-left: 27px;

    background: none;
    border: none;

    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--col-ow-1);
  }
  > input::placeholder {
    color: var(--col-grey);
  }

  > button {
    height: 100%;
    padding: 0 25px;

    opacity: 1;
    transition: opacity var(--tr-dur) var(--tr-ease);

    > svg {
      fill: #444B60;
      transition: fill var(--tr-dur) var(--tr-ease);
    }
    &:disabled {
      opacity: var(--disabled-opacity);
    }
    &:not(:disabled) {
      &:hover > svg {
        fill: var(--col-grey);
      }
      &:active > svg {
        fill: #444B60;
      }
    }
  }
}
