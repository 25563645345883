.container {
  position: relative;
  padding: 23px 20px 27px;

  display: flex;
  flex-direction: column;

  background: linear-gradient(230.38deg, #242650 0%, rgba(16, 18, 47, 0) 100%, #26283A 100%);
  border-radius: 32px;
  overflow: hidden;
}
.container.mobile {
  padding: 0;
  padding-bottom: 11%;

  border-radius: 0;
}

.bg-image {
  object-fit: cover;
  mix-blend-mode: color-dodge;
  opacity: 0.21;
}

.header {
  position: relative;
  display: flex;
  
  z-index: 1;
}

.heading {
  margin-right: 25px;
}

.multipliers-scroll-area {
  flex: 1;
}
.container.mobile .multipliers-scroll-area {
  height: 68px;
}

.multipliers {
  --half-padding: 5.5px;

  display: flex;
  align-items: center;
  
  padding: var(--half-padding);
}
.container.mobile .multipliers {
  --half-padding: 4px;

  padding-left: 15px;
  padding-right: 15px;
}

.multiplier {
  margin: var(--half-padding);
}

.chart {
  position: relative;
  margin-top: 33px;
  height: 0;
  flex: 1;
}
