.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #E3E6F1;
}
.container.mobile > .header {
  font-size: 12px;
  line-height: 15px;
}

.bank {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: wrap;

  > span {
    white-space: nowrap;
  }
  > span:last-child {
    color: #7982A5;
  }
}

.players {
  margin-top: 23px;
}
.container.mobile > .players {
  margin-top: 14px;
}

.player:not(:first-child) {
  margin-top: 5px;
}
