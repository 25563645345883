.container {

}

.header {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 309px;

  /* Make all children absolute */
  > :not(.bg-image) {
    position: absolute;
  }
}
.container.mobile > .header {
  height: 120px;
}

.bg-image {
  height: 100%;
  width: 100%;
}

.speech-image {
  top: 9.39%;
  left: 18.25%;
  height: 42.92%;
}

.smiley-image {
  top: 8.74%;
  right: 8.1%;
  height: 26.21%;
}

.star-image {
  bottom: -8.41%;
  left: 5.29%;
  height: 22.91%;
}

.titles {
  top: 58px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 54px;
  max-width: 475px;

  > span {
    text-transform: uppercase;
  }

  > span:first-child {
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    color: #91DEFF;
  }

  > span:last-child {
    --ellipsis-max-lines: 3;

    margin-top: 18px;

    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: white;
  }
}
.container.mobile .titles {
  top: 16px;

  margin: 0 20px;
  max-width: 240px;

  > span:first-child {
    font-size: 16px;
    line-height: 19px;
  }

  > span:last-child {
    --ellipsis-max-lines: 2;

    margin-top: 4px;

    font-size: 18px;
    line-height: 22px;
  }
}

.stats-bar {
  position: absolute;
  bottom: -13px;
  min-width: 48.8%;
  max-width: 57%;
}
.container.mobile .stats-bar {
  bottom: unset;
  top: calc(100% + 6px);
  min-width: min(67%, 260px);
  max-width: 260px;
  overflow: visible;
}

.link-bar {
  margin-top: 68px;
}
.container.mobile > .link-bar {
  margin-top: 54px;

  /* Raise above main (transparent) scroll 
  viewport, so can recieve pointer events */
  position: relative;
  z-index: 1;
}
