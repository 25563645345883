.container {
  --width: 141px;

  position: absolute;
  top: 0;
  height: 100%;
  width: var(--width);
  left: calc(-1 * var(--width));

  background: #1A1F2B;
  box-shadow: 9px 4px 20px rgba(0, 0, 0, 0);

  transition: all var(--tr-dur) var(--tr-ease);
}
.container.visible {
  left: 0;
  box-shadow: 9px 4px 20px rgba(0, 0, 0, 0.25);
}

.scroll-area {
  height: 100%;
}

.list {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  > hr {
    width: 100%;
    height: 1px;
    margin: 0;

    background: #2C3343;
    border: none;
  }
}

.games {
  display: flex;
  flex-direction: column;

  padding: 11px 0;
}

.game-link {
  display: flex;
  align-items: center;

  padding: 19px 18px;

  text-decoration: none;

  /* Inner div used to standardise width of icons */
  > div {
    flex-shrink: 0;

    display: flex;
    justify-content: center;

    width: 24px;
  }

  svg {
    fill: var(--col-grey);

    transition: fill var(--tr-dur) var(--tr-ease);
  }
  &.active svg {
    fill: var(--col-ow-2);
  }

  > span {
    margin-left: 7px;

    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--col-grey);

    transition: color var(--tr-dur) var(--tr-ease);
  }
  &.active > span {
    color: var(--col-ow-2);
  }
}

.nav-links {
  display: flex;
  flex-direction: column;

  padding: 17.5px 0;
}

.nav-link {
  padding: 12.5px 16px;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  color: #777A82;

  transition: color var(--tr-dur) var(--tr-ease);

  &.active {
    color: #FFFDF8;
  }
}
