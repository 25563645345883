.container {
  flex: 1;
  display: flex;
}

.scroll-area {
  flex: 1;
  position: relative;
}

.table {
  --v-spacing: 11px;

  width: 100%;
  border-spacing: 0 var(--v-spacing);
  padding: calc(var(--layout-v-padding) - var(--v-spacing)) 0;

  td, th {
    padding: 12px 20px 11px;
  }
  th {
    padding-top: 5px;
    
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: white;
    opacity: 0.4;

    &:first-child {
      padding-left: 35px;
    }
  }
}
.container.mobile .table {
  padding: 23px 16px;

  td, th {
    padding: 12px 13px 11px;
  }
}
