.container {
  opacity: 0;
  pointer-events: none;

  transition: opacity var(--tr-dur) var(--tr-ease);
}
.container.visible {
  opacity: 1;
  pointer-events: all;
}
