.container {
  --padding: 15px;
  --gradient-border-width: 1px;
  --gradient-border-bg: linear-gradient(180deg, #7386FF 0%, #4B61F2 50%, #394BC0 100%);
  --gradient-border-box-bg: linear-gradient(180deg, #586DF8 0%, #3D55EB 100%);
  --offset-x: 0px;
  --offset-y: 0px;

  padding-left: calc(var(--padding) + 0.5 * var(--offset-x));
  padding-right: calc(var(--padding) - 0.5 * var(--offset-x));
  padding-top: calc(var(--padding) + 0.5 * var(--offset-y));
  padding-bottom: calc(var(--padding) - 0.5 * var(--offset-y));

  border-radius: 12px;

  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: white;

  &:active {
    --gradient-border-bg: linear-gradient(0deg, #7386FF 0%, #4B61F2 50%, #394BC0 100%);
    --gradient-border-box-bg: linear-gradient(0deg, #586DF8 0%, #3D55EB 100%);
    --offset-x: 1px;
    --offset-y: 3px;
  }
}
.container.red {
  --gradient-border-bg: linear-gradient(180deg, #FC7375 0%, #CF4049 50%, #9C2D2F 100%);
  --gradient-border-box-bg: linear-gradient(180deg, #D84B4B 0%, #C63445 100%);

  &:active {
    --gradient-border-bg: linear-gradient(0deg, #FC7375 0%, #CF4049 50%, #9C2D2F 100%);
    --gradient-border-box-bg: linear-gradient(0deg, #D84B4B 0%, #C63445 100%);
  }
}
