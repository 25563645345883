.container {
  display: flex;
  align-items: center;

  padding-left: 26.5px;
  padding-right: 6px;

  background: #1F202F;
  border-radius: 14px;
}

.icon {

}

.body {
  flex: 1;
  width: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 0 15.5px;

  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--col-grey);
  }

  > a {
    margin-top: 3px;
    max-width: 100%;

    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    color: #3C94DD;
  }
}
.container.mobile > .body {
  > a {
    font-size: 12px;
    line-height: 15px;
  }
}

.button {
  --size: 61px;

  height: var(--size);
  width: var(--size);

  > svg {
    fill: var(--col-grey);

    transition: fill var(--tr-dur) var(--tr-ease);
  }
  &:hover > svg {
    fill: #3C94DD;
  }
  &:active > svg {
    fill: var(--col-grey);
  }
}
