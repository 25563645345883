.container {
  flex: 1;
  display: flex;
}

.sidebar {
  flex: 1;
}

.spacer {
  min-height: var(--layout-v-padding);
  min-width: var(--layout-h-padding);
}

.content {
  flex: 2.18;
}
