.container {
  --border: 2px solid #2A2F3B;
  --border-radius: 18px;

  background: #1C212D;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;

  &.podium {
    background-attachment: fixed;
  }
  &.pos-1 {
    background-image: linear-gradient(90deg, #C2A331 var(--left-offset-perc), #292933 var(--right-offset-perc));
  }
  &.pos-2 {
    background-image: linear-gradient(90deg, #92693A var(--left-offset-perc), #292933 var(--right-offset-perc));
  }
  &.pos-3 {
    background-image: linear-gradient(90deg, #3B77D0 var(--left-offset-perc), #292933 var(--right-offset-perc));
  }

  > td:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  > td:last-child {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  &:not(.podium) > td {
    border-top: var(--border);
    border-bottom: var(--border);

    &:first-child {
      border-left: var(--border);
    }
    &:last-child {
      border-right: var(--border);
    }
  }
}
.container.mobile {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.container.mobile > .player-cell {
  max-width: 100px;
}

.player {

}

.amount {

}

.medal-cell {

}