.container {
  max-width: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;

  background: #141721;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.header {
  padding: 25px 36px;

  background: #161923;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  
  > span {
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    line-height: 21px;
    color: #D4D7E4;
  }
}
.container.mobile > .header {
  padding: 25px 20px
}

.content {
  padding: 29px 36px 39px;

  display: flex;
  flex-direction: column;
}
