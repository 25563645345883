.container {
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;

  background: black;
  opacity: 0;

  pointer-events: none;

  transition: opacity var(--tr-dur) var(--tr-ease);
}
.container.visible {
  opacity: 0.4;
  pointer-events: all;
}
