.container {
  padding: 14px 20px 15px;

  background: #1D222E;
  border-radius: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  > span:first-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;
  }

  > span:last-child {
    margin-left: 12px;

    font-weight: 500;
    font-size: 11px;
    white-space: nowrap;
    line-height: 13px;
    color: #555C67;
  }
}

.content {
  margin-top: 5px;
  margin-bottom: 0;

  overflow-x: scroll;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #9DA4AD;
}
