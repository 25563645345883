.container {
  display: flex;
  align-items: center;
}

.position {
  --gradient-border-width: 2px;

  display: flex;
  align-items: center;

  height: calc(54px - 2 * var(--gradient-border-width));
  padding: 0 13px;

  border-radius: 14px;

  &.podium {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.5);
  }
  &.pos-1 {
    --gradient-border-bg: linear-gradient(180deg, #EDD176 0%, #C29D1D 41%, #967918 100%);
    --gradient-border-box-bg: linear-gradient(180deg, #E1B621 0%, #967918 100%);
  }
  &.pos-2 {
    --gradient-border-bg: linear-gradient(180deg, #E6BC8A 0%, #A77930 42.6%, #685617 100%);
    --gradient-border-box-bg: linear-gradient(180deg, #D89443 0%, #685617 100%);
  }
  &.pos-3 {
    --gradient-border-bg: linear-gradient(180deg, #84AFF0 0%, #2C63BA 41%, #1D3E79 100%);
    --gradient-border-box-bg: linear-gradient(180deg, #377DE8 0%, #1D3E79 100%);
  }
}
.container.mobile > .position {
  height: unset;
  padding: 0;

  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
}

.image {
  --size: 50px;

  height: var(--size);
  width: var(--size);

  margin-left: 29px;

  border-radius: 999px;
}
.container.mobile > .image {
  --size: 22px;

  margin-left: 12px;
}

.name {
  margin-left: 15px;
}
