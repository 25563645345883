.container {
  display: flex;
  flex-direction: column;
}

.user:not(:first-child) {
  margin-top: 9px;
}
.container.mobile > .user:not(:first-child) {
  margin-top: 6px;
}
