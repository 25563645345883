.container {
  min-width: 0;

  background: #1B202E;
  border: 2px solid #2B2F45;
  border-radius: 15px;

  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: white;

  transition: border-color var(--tr-dur) var(--tr-ease);

  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--col-grey);
  }

  &:focus {
    border-color: #555C67;
  }

  &.mobile {
    font-size: 17px;
    line-height: 21px;
  }
}
