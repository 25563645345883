.container {
  display: flex;
  flex-direction: column;
}

.field {
  display: flex;

  > input {
    flex: 1;
    width: 0;
  }

  > button {
    --size: 55px;
    --padding: 15px;
    --gradient-border-width: 2px;
    --gradient-border-bg: linear-gradient(180deg, #3E4454 0%, #2D3240 100%);
    --gradient-border-box-bg: linear-gradient(180deg, #353C49 0%, #2D3240 100%);
    --offset-x: 0px;
    --offset-y: 0px;

    min-height: var(--size);
    width: var(--size);
    
    margin-left: 5px;

    padding-left: calc(var(--padding) + 0.5 * var(--offset-x));
    padding-right: calc(var(--padding) - 0.5 * var(--offset-x));
    padding-top: calc(var(--padding) + 0.5 * var(--offset-y));
    padding-bottom: calc(var(--padding) - 0.5 * var(--offset-y));

    border-radius: 12px;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: white;

    &:active {
      --gradient-border-bg: linear-gradient(0deg, #3E4454 0%, #2D3240 100%);
      --gradient-border-box-bg: linear-gradient(0deg, #353C49 0%, #2D3240 100%);
      --offset-x: 1px;
      --offset-y: 2px;
    }
  }
}
.container.mobile > .field {
  > button {
    --size: 64px;

    margin-left: 8px;
  }
}
