.container {
  display: flex;

  background: #0F121B;
}

.currency-bar {
  min-width: 240px;
  box-sizing: border-box;
}
.container.mobile > .currency-bar {
  min-width: unset;
}

.content {
  display: flex;
  flex-direction: column;

  padding: 26px 24px 39px;
}
.container.mobile > .content {
  padding: 26px 12px 22px;
}

.header {
  display: flex;
  align-items: center;

  > span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
}

.icon {
  margin-right: 10px;
}

.amount-form {
  margin-top: 27px;
}

.promo-form {
  margin-top: 19px;
}
.container.mobile .promo-form {
  margin-top: 28px;
}

.extrema {
  margin-top: 36px;

  display: flex;
  flex-direction: column;
}
.container.mobile .extrema {
  margin-top: 55px;
}

.extremum {
  > span {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #E3E6F1;
  }
  > span:last-child {
    color: #6B6C83;
  }
}
.extremum:last-child {
  margin-top: 11px;
}
