.container {
  --radix-scroll-area-thumb-width: 7px;
}

.viewport {
  scroll-behavior: smooth;

  > div {
    display: block !important;
    min-width: unset !important;
  }
}

.scrollbar {
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 2px;
}
.scrollbar[data-state="visible"] {
  animation: fade-in 0.2s forwards;
}
.scrollbar[data-state="hidden"] {
  animation: fade-out 0.2s forwards;
}

.thumb {
  flex: 1;
  background: var(--col-grey);;
  border-radius: var(--radix-scroll-area-thumb-width);
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  position: relative;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
