.container {
  flex-shrink: 0;
  
  padding: 10px 15px;

  border: 2px solid #36456C;
  border-radius: 49px;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: white;
}
.container.high {
  border-color: #849687;
}
