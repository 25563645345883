.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  overflow-x: scroll;
}

.stat {
  display: flex;

  &:not(:first-child) {
    margin-left: 15px;
  }
}
.container.mobile > .stat {
  align-items: flex-start;

  &:not(:first-child) {
    margin-left: 22px;
  }
}

.text {
  margin-left: 14px;

  display: flex;
  flex-direction: column;

  > span {
    white-space: nowrap;
    color: white;
  }

  > span:first-child {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
  }

  > span:last-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
.container.mobile .text {
  margin-left: 6px;

  > span:first-child {
    font-size: 14px;
    line-height: 17px;
  }

  > span:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
