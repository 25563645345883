.container {
  display: flex;
}

.tab {
  width: 118px;
  padding-top: var(--sidebar-padding);

  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:first-child) {
    margin-left: 18px;
  }

  > h3 {
    transition: color var(--tr-dur);
  }
  &[data-state="inactive"] > h3 {
    color: #80889D;
  }

  > hr {
    height: 2px;
    width: 0px;
    margin: 0;
    margin-top: 13px;

    background: #5066F4;
    border: none;

    transition: width var(--tr-dur);
  }
  &[data-state="active"] > hr {
    width: 100%;
  }
}
