.container {
  position: relative;
}

.inner {
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
}
.container.horizontal > .inner {
  overflow-y: unset;
  overflow-x: scroll;
  
  flex-direction: row;
}
