.container {
  display: flex;
  align-items: center;

  /* Chevron */
  > svg {
    margin-left: 8px;
  }
}

.image {
  --size: 53px;
  --color: #EE9A5A;

  width: var(--size);
  height: var(--size);

  background: var(--color);
  border: 2px solid var(--color);
  border-radius: 999px;

  user-select: none;
}
